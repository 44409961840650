import React from "react"
import { Link } from "gatsby"
import "./pagination.scss";

const Pagination = (props) => {
  const pageInfo = props.pageInfo
  
  return (
    <nav className="pagination">
      {/* Previous page */}
      {pageInfo.hasPreviousPage ? (
        <Link to={pageInfo.currentPage === 2 ? "/articles/" : `/articles/${pageInfo.currentPage - 1}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
            <path d="M12.707 17.293L8.414 13H18v-2H8.414l4.293-4.293l-1.414-1.414L4.586 12l6.707 6.707z"/>
          </svg>
        </Link>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
          <path d="M12.707 17.293L8.414 13H18v-2H8.414l4.293-4.293l-1.414-1.414L4.586 12l6.707 6.707z"/>
        </svg>
      )}
      {/* Current page */}
      <p>{pageInfo.currentPage}</p>
      {/* Next page */}
      {pageInfo.hasNextPage ? (
        <Link to={`/articles/${pageInfo.currentPage + 1}/`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
            <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707l-1.414 1.414L15.586 11H6v2h9.586z"/>
          </svg>
        </Link>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
          <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707l-1.414 1.414L15.586 11H6v2h9.586z"/>
        </svg>
      )}
    </nav>
  )
}

export default Pagination
