import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumb from '../components/breadcrumb/breadcrumbs';
import Pagination from '../components/pagination/pagination';

const AllPosts = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const pageInfo = data.allMarkdownRemark.pageInfo

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Articles" />
        <p>
          Aucun article trouvé.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Articles" />
      <Breadcrumb location={location}/>
      <h1>TOUS LES ARTICLES</h1>
      <ol className="list-articles">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          // Remove accent
          const slug = post.fields.slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          
          return (
            <li key={post.fields.slug}>
              {/* Card of an article */}
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <div className="cardContentPost">
                  <Link to={`/articles${slug}`}>
                    <img src={post.frontmatter.img.childImageSharp.fluid.src} alt="La première de couverture du livre"/>
                  </Link>
                  <header>
                    <small>{post.frontmatter.date}</small>
                    <h2>
                      <Link to={`/articles${slug}`}>{title}</Link>
                    </h2>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                  <Link className="cardBtnPost btn" to={`/articles${slug}`}>
                    Lire l'article
                  </Link>
                </div>
              </article>
            </li>
          )
        })}
      </ol>
      <Pagination pageInfo={pageInfo}/>
    </Layout>
  )
}

export default AllPosts

export const postQuery = graphql`
  query getPosts($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`
